<template>
    <ul class="grid-wrapper cols list" :class="`cols-${cols}`">
        <base-font variant="listItem" class="list-item" v-for="value in values" :key="value">{{value}}</base-font>
    </ul>
</template>

<script>

export default {
	props: {
		values: Object,
		cols: {
			type: String,
			default: '2'
		}
	}
}
</script>

<style lang="scss" scoped>
    .list {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .grid-wrapper {
	display: grid;
	gap: 1rem;
	@include media-breakpoint-landscape {
		place-items: center;
	}
	@include media-breakpoint-up(xl) {
		gap: 2rem;
	}
}
.cols {
    grid-template-columns: repeat(2, 1fr);
	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(4, 1fr);
	}
}
</style>
